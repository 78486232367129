// import built-in modules
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Imoprt third-party modules
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Divider,
  Image,
  Select,
  Form,
  Input,
  Button,
  Tooltip,
  Radio,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

// Import goldendreams modules
import {
  authBusinessSignup,
  authSignupClearErrors,
} from "../../common/redux/actions/AuthenticationActions";

// Import styles
import styles from "./RegistrationView.module.css";

const { Option } = Select;

export default function RegistrationView(props, context) {
  const dispatch = useDispatch();
  const history = useHistory();
  const authentication = useSelector((state) => state.AuthenticationState);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    delete values["confirm"];
    dispatch(authBusinessSignup(values));
  };

  useEffect(() => {
    if (authentication.errors.signup) {
      setTimeout(() => dispatch(authSignupClearErrors()), 5000);
    }
  }, [authentication.errors.signup]);

  return (
    <>
      <Row className={styles.containerRow}>
        <Col className={styles.leftColumn} xs={24} lg={12}>
          <center>
            <div className={styles.imageContainer}>
              <Image
                width="100%"
                src="/static/media/goldenCloud.png"
                preview={false}
              />
            </div>
            <h1 className={styles.title} style={{ marginBottom: 0 }}>
              {" "}
              {context.t("Golden Dreams Job Marketplace")}{" "}
            </h1>
            <h3 className={styles.title}>
              {" "}
              {context.t("Powered by Issara Institute")}{" "}
            </h3>
            <h4 className={styles.description}>
              {context.t(
                "Register and login here to modernize and digitize how your business manages labour recruitment ->",
              )}
            </h4>
          </center>
        </Col>
        <Col className={styles.rightColumn} xs={24} lg={12}>
          <div className={styles.formContainer}>
            <h3 className={styles.formTitle}>
              {" "}
              {context.t("Create Account")}{" "}
            </h3>
            <Form
              form={form}
              name="register"
              layout="vertical"
              requiredMark={false}
              onFinish={onFinish}
              initialValues={{
                type: "EM",
              }}
              scrollToFirstError
            >
              <Form.Item
                name="type"
                label={context.t("We are:")}
                rules={[
                  {
                    required: true,
                    message: context.t("Please select your business type!"),
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={"EM"}> {context.t("Employer")} </Radio>
                  <Radio value={"RA"}>
                    {" "}
                    {context.t("Recruitment Agency")}{" "}
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="name"
                label={
                  <span>
                    {context.t("Business Name")}&nbsp;
                    <Tooltip
                      title={context.t("What is your offical business name?")}
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: context.t("Please input your business name!"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label={context.t("Email")}
                rules={[
                  {
                    type: "email",
                    message: context.t("The input is not valid E-mail!"),
                  },
                  {
                    required: true,
                    message: context.t("Please input your E-mail!"),
                  },
                ]}
                {...(authentication.errors.signup && {
                  help: authentication.errors.signup.message,
                  validateStatus: "error",
                })}
                hasFeedback
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label={context.t("Password")}
                rules={[
                  {
                    required: true,
                    message: context.t("Please input your password!"),
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="confirm"
                label={context.t("Confirm Password")}
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: context.t("Please confirm your password!"),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        context.t(
                          "The two passwords that you entered do not match!",
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item style={{ marginBottom: "0.5rem" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", marginTop: "0.5rem" }}
                  loading={authentication.loading}
                >
                  {context.t("Create your free account")}
                </Button>
              </Form.Item>

              <p className={styles.termsConditions}>
                {context.t(
                  "By clicking register, you agree to our {privacy_policy}.",
                  {
                    privacy_policy: (
                      <a href="/privacy-policy" target="_blank">
                        {context.t("privacy policy")}
                      </a>
                    ),
                  },
                )}
              </p>
              <p className={styles.haveAccount}>
                {context.t("Already have an account?")}{" "}
                <a onClick={() => props.history.push("/login")}>
                  {" "}
                  {context.t("Login here")}{" "}
                </a>
              </p>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
}

RegistrationView.contextTypes = {
  t: PropTypes.func.isRequired,
};
