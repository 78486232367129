// import built-in modules
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Imoprt third-party modules
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Result, Button } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

// Import goldendreams modules
import { clearAuthLocalStorage } from "../../../common/redux/actions/AuthenticationActions";

// Import styles
import styles from "./UnathorizedView.module.css";

export default function UnathorizedView(props, context) {
  const history = useHistory();

  const profile = useSelector((state) => state.ProfileState.data);

  const useAnotherAccount = () => {
    clearAuthLocalStorage();
    history.push("/login");
    window.location.reload();
  };

  return (
    <div className={styles.container}>
      <Result
        status="403"
        title="403"
        subTitle={context.t(
          "Sorry, you are not authorized to access this page.",
        )}
        extra={[
          <Button key={1} onClick={useAnotherAccount}>
            {context.t("Use another account")}
          </Button>,
          <Button
            key={2}
            type="primary"
            onClick={() =>
              history.push(
                `console-${profile.type == "RA" ? "recruiter" : "employer"}`,
              )
            }
          >
            {context.t("Go back to console")}
          </Button>,
        ]}
      />
    </div>
  );
}

UnathorizedView.contextTypes = {
  t: PropTypes.func.isRequired,
};
