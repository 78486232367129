// import built-in modules
import React, { useState } from "react";
import PropTypes from "prop-types";

// Imoprt third-party modules
import axios from "axios";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { Form, Input, Result, Button } from "antd";

// Import styles
import styles from "./PasswordResetView.module.css";

export default function PasswordResetView(props, context) {
  const history = useHistory();

  let { token } = useParams();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const goToLogin = () => {
    history.push("/login");
  };

  const onFinish = (values) => {
    const payload = { password: values.password, token: token };
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/authentication/password-reset/confirm/`,
        payload,
      )
      .then((res) => {
        setResult(
          <Result
            status="success"
            title={context.t("Successfully changed password")}
            subTitle={context.t("You'll be redirected to login page shortly")}
            extra={[
              <Button onClick={goToLogin}>
                {context.t("Go to login page")}
              </Button>,
            ]}
          />,
        );
        setTimeout(goToLogin, 5000);
      })
      .catch((error) => {
        setResult(
          <Result
            status="warning"
            title={context.t("Password reset failed")}
            subTitle={context.t(
              "The reset link has expired. Please request a new one.",
            )}
            extra={[
              <Button onClick={goToLogin}>
                {context.t("Go to login page")}
              </Button>,
            ]}
          />,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      {result ? (
        result
      ) : (
        <div className={styles.formContainer}>
          <Form
            form={form}
            name="password-reset"
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              name="password"
              label={context.t("New Password")}
              rules={[
                {
                  required: true,
                  message: context.t("Please input your password!"),
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label={context.t("Confirm Password")}
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: context.t("Please confirm your password!"),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      context.t(
                        "The two passwords that you entered do not match!",
                      ),
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item style={{ marginBottom: "0.5rem" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", marginTop: "0.5rem" }}
                loading={loading}
              >
                {context.t("Update password")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
}

PasswordResetView.contextTypes = {
  t: PropTypes.func.isRequired,
};
