// import built-in modules
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Imoprt third-party modules
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Image, Form, Input, Button } from "antd";

// Import goldendreams modules
import ForgotPasswordModal from "./ForgotPasswordModal";
import {
  authLogin,
  authLoginClearErrors,
} from "../../common/redux/actions/AuthenticationActions";

// Import styles
import styles from "./LoginView.module.css";

export default function LoginView(props, context) {
  const dispatch = useDispatch();

  const authentication = useSelector((state) => state.AuthenticationState);

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [prepopulatedForgotPasswordEmail, setPrepopulatedForgotPasswordEmail] =
    useState("");

  const [form] = Form.useForm();

  const onFinish = (values) => {
    dispatch(authLogin(values));
  };

  const handleOpenForgotPasswordModal = () => {
    setShowForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
  };

  useEffect(() => {
    if (authentication.errors.login) {
      setTimeout(() => dispatch(authLoginClearErrors()), 5000);
    }
  }, [authentication.errors.login]);

  return (
    <>
      <ForgotPasswordModal
        visible={showForgotPasswordModal}
        onCancel={handleCloseForgotPasswordModal}
        prepopulatedEmail={prepopulatedForgotPasswordEmail}
      />

      <Row className={styles.containerRow}>
        <Col className={styles.leftColumn} xs={24} lg={12}>
          <center>
            <div className={styles.imageContainer}>
              <Image
                width="100%"
                src="/static/media/goldenCloud.png"
                preview={false}
              />
            </div>
            <h1 className={styles.title} style={{ marginBottom: 0 }}>
              {context.t("Golden Dreams Job Marketplace")}
            </h1>
            <h3 className={styles.title}>
              {context.t("Powered by Issara Institute")}
            </h3>
            <h4 className={styles.description}>
              {context.t(
                "Register and login here to modernize and digitize how your business manages labour recruitment ->",
              )}
            </h4>
          </center>
        </Col>
        <Col className={styles.rightColumn} xs={24} lg={12}>
          <div className={styles.formContainer}>
            <h3 className={styles.formTitle}> {context.t("Login")} </h3>
            <Form
              form={form}
              name="register"
              layout="vertical"
              requiredMark={false}
              onFinish={onFinish}
              initialValues={{
                residence: ["zhejiang", "hangzhou", "xihu"],
                prefix: "86",
              }}
              scrollToFirstError
            >
              <Form.Item
                name="email_username"
                label={context.t("Email")}
                rules={[
                  {
                    type: "email",
                    message: context.t("The input is not valid Email"),
                  },
                  {
                    required: true,
                    message: context.t("Please input your Email"),
                  },
                ]}
                validateStatus={authentication.errors.login && "error"}
                help={
                  authentication.errors.login &&
                  authentication.errors.login.message
                }
                hasFeedback
              >
                <Input
                  onChange={(e) =>
                    setPrepopulatedForgotPasswordEmail(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                name="password"
                label={
                  <div className={styles.passwordLabel}>
                    {context.t("Password")}
                    <a
                      className={styles.forgotPassword}
                      onClick={handleOpenForgotPasswordModal}
                    >
                      {context.t("Forgot Password?")}
                    </a>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: context.t("Please input your password!"),
                  },
                ]}
                {...(authentication.errors.login && {
                  help: authentication.errors.login.message,
                  validateStatus: "error",
                })}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <Form.Item style={{ marginBottom: "0.5rem" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", marginTop: "0.5rem" }}
                  loading={authentication.loading}
                >
                  {context.t("Login")}
                </Button>
              </Form.Item>
              <p className={styles.haveAccount}>
                {context.t("Not registered?")}
                <a onClick={() => props.history.push("/registration")}>
                  {context.t("Create an account")}
                </a>
              </p>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
}

LoginView.contextTypes = {
  t: PropTypes.func.isRequired,
};
