// import built-in modules
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Imoprt third-party modules
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";

// Import styles
import styles from "./index.module.css";

const fuzzysort = require("fuzzysort");

export default function RejectionReasonSelect(props, context) {
  const rejectionReasons = useSelector((state) => state.RejectionReasonsState);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(props.value);

  // Update selected value from props
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  // Prepare options
  useEffect(() => {
    const arr = rejectionReasons.itemsArray
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((status) => {
        return { value: status.rejection_reason, label: status.name };
      });
    setOptions(arr);
  }, [rejectionReasons.itemsArray]);

  const onChange = (value) => {
    props.onChange(value); // pass value to a parent component
    setValue(value); // change internal state value
  };

  return (
    <Select
      value={value}
      style={props.style}
      placeholder={context.t("Select Reason")}
      onChange={onChange}
      loading={rejectionReasons.loading}
      options={options}
      disabled={props.disabled || !rejectionReasons.fetched}
      getPopupContainer={(node) => node.parentNode}
      showAction="focus"
    />
  );
}

RejectionReasonSelect.contextTypes = {
  t: PropTypes.func.isRequired,
};

RejectionReasonSelect.defaultProps = {
  onChange: (reason) => console.log("Selected reason: ", reason),
};
