// import built-in modules
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Imoprt third-party modules
import moment from "moment";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  Button,
  Divider,
  Image,
  Input,
  Popconfirm,
  Spin,
  message,
} from "antd";
import {
  StopOutlined,
  DownloadOutlined,
  CheckOutlined,
  SendOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import RejectionReasonSelect from "../../../components/Selects/RejectionReasonSelect";

import {
  Genders,
  LanguageProficiencyLevels,
} from "../../../common/utilities/choices";

import { getCities } from "../../../common/redux/actions/CitiesActions";
import { getCountries } from "../../../common/redux/actions/CountriesActions";
import { getNationalities } from "../../../common/redux/actions/NationalitiesActions";
import { getEducationLevels } from "../../../common/redux/actions/EducationLevelsActions";
import { getIndustries } from "../../../common/redux/actions/IndustriesActions";
import { getLanguages } from "../../../common/redux/actions/LanguagesActions";
import { getMyJobs } from "../../../common/redux/actions/MyJobsActions";
import { getRejectionReasons } from "../../../common/redux/actions/RejectionReasonsActions";
import { getStates } from "../../../common/redux/actions/StatesActions";
import {
  downloadJobApplication,
  offerJobApplication,
  rejectJobApplication,
  requestDocumentsJobApplication,
  shortlistJobApplication,
} from "../../../common/redux/actions/MyJobsActions";
import { readNotification } from "../../../common/redux/actions/ProfileActions";

// Import styles
import styles from "./EmployerApplicationsDetailedView.module.css";

export default function EmployerApplicationsDetailedView(props, context) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { jobId, applicationId } = useParams();

  const cities = useSelector((state) => state.CitiesState);
  const countries = useSelector((state) => state.CountriesState);
  const nationalities = useSelector((state) => state.NationalitiesState);
  const educationLevels = useSelector((state) => state.EducationLevelsState);
  const genders = Genders({}, context, false);
  const jobs = useSelector((state) => state.MyJobsState);
  const industries = useSelector((state) => state.IndustriesState);
  const language = useSelector((state) => state.i18nState.lang);
  const languageProficiencyLevels = LanguageProficiencyLevels(
    {},
    context,
    false,
  );
  const languages = useSelector((state) => state.LanguagesState);
  const profile = useSelector((state) => state.ProfileState.data);
  const rejectionReasons = useSelector((state) => state.RejectionReasonsState);
  const states = useSelector((state) => state.StatesState);

  const [application, setApplication] = useState({});

  const [job, setJob] = useState(null);

  const [printable, setPrintable] = useState(
    location.pathname.indexOf("print") > 0,
  );
  const [offeringJob, setOfferingJob] = useState(false);
  const [rejectingApplication, setRejectingApplication] = useState(false);
  const [requestingDocuments, setRequestingDocuments] = useState(false);
  const [shortlistingApplication, setShortlistingApplication] = useState(false);

  const [rejectionReason, setRejectionReason] = useState({
    rejection_reason: 2,
  });

  // If printable (opened in a new window with print in url):
  // change the document title, open print dialog and close the tab after done
  useEffect(() => {
    if (printable && Object.keys(application).length > 0) {
      document.title = `job_${jobId}_application_${application.id}_${language}`;

      // let images load
      setTimeout(() => {
        window.print();
        window.close();
      }, 500);
    }

    if (jobs.fetched && application && jobs.items[application.job]) {
      setJob(jobs.items[application.job]);
    }
  }, [application, jobs]);

  // mark notification as read (if any)
  useEffect(() => {
    markAsRead();
  }, [profile.notifications, application]);

  useEffect(() => {
    !cities.fetched && dispatch(getCities());
    !countries.fetched && dispatch(getCountries());
    !nationalities.fetched && dispatch(getNationalities());
    !educationLevels.fetched && dispatch(getEducationLevels());
    !industries.fetched && !industries.loading && dispatch(getIndustries());
    !jobs.fetched && !jobs.loading && dispatch(getMyJobs("EM"));
    !languages.fetched && dispatch(getLanguages());
    !rejectionReasons.fetched &&
      !rejectionReasons.loading &&
      dispatch(getRejectionReasons());
    !states.fetched && dispatch(getStates());
  }, []);

  useEffect(() => {
    if (jobs.fetched) {
      if (jobs.items[jobId]) {
        let application = jobs.items[jobId].applications.filter(
          (app) => app.id == applicationId,
        )[0];
        application ? setApplication(application) : history.push("/404");
      }
    }
  }, [jobs.items]);

  const downloadApplication = () => {
    if (["APPLIED", "VIEWED"].includes(application.status)) {
      dispatch(downloadJobApplication(application.id));
    }
  };

  // send the job offer
  const offerApplication = () => {
    setOfferingJob(true);

    dispatch(offerJobApplication(application.id))
      .then((res) => {
        message.success(context.t("Job offer has been successfully sent."), 5);
        history.action === "PUSH"
          ? history.goBack()
          : history.push(
              `/console-employer/jobs/${jobId}/applications?status=OFFERED&genders=M,F,X,N`,
            );
      })
      .catch((error) =>
        message.error(
          <div style={{ display: "inline-grid", textAlign: "left" }}>
            <p> {context.t("An error occurred while sending job offer.")} </p>
            {error.response && JSON.stringify(error.response.data)}
          </div>,
          7,
        ),
      )
      .finally(() => {
        setOfferingJob(false);
      });
  };

  const rejectApplication = () => {
    setRejectingApplication(true);

    dispatch(rejectJobApplication(application.id, rejectionReason))
      .then((res) => {
        message.success(context.t("Application has been rejected"), 5);
        history.action === "PUSH"
          ? history.goBack()
          : history.push(
              `/console-employer/jobs/${jobId}/applications?status=REJECTED_BY_BUSINESS&genders=M,F,X,N`,
            );
      })
      .catch((error) =>
        message.error(
          <div style={{ display: "inline-grid", textAlign: "left" }}>
            <p>{context.t("An error occurred while rejecting application.")}</p>
            {error.response && JSON.stringify(error.response.data)}
          </div>,
          7,
        ),
      )
      .finally(() => setRejectingApplication(false));
  };

  const requestDocuments = () => {
    setRequestingDocuments(true);

    dispatch(requestDocumentsJobApplication(application.id))
      .then((res) => {
        message.success(context.t("A request for documents has been sent."), 5);
        history.action === "PUSH"
          ? history.goBack()
          : history.push(
              `/console-employer/jobs/${jobId}/applications?status=PENDING&genders=M,F,X,N`,
            );
      })
      .catch((error) =>
        message.error(
          <div style={{ display: "inline-grid", textAlign: "left" }}>
            <p>
              {context.t(
                "An error occurred while sending request for documents.",
              )}
            </p>
            {error.response && JSON.stringify(error.response.data)}
          </div>,
        ),
      )
      .finally(() => {
        setRequestingDocuments(false);
      });
  };

  const shortlistApplication = () => {
    setShortlistingApplication(true);

    dispatch(shortlistJobApplication(application.id))
      .then((res) => {
        message.success(context.t("Application has been shortlisted"), 5);
        history.action === "PUSH"
          ? history.goBack()
          : history.push(
              `/console-employer/jobs/${jobId}/applications?status=SHORTLISTED&genders=M,F,X,N`,
            );
      })
      .catch((error) =>
        message.error(
          <div style={{ display: "inline-grid", textAlign: "left" }}>
            <p>
              {context.t("An error occurred while shortlisting application.")}
            </p>
            {error.response && JSON.stringify(error.response.data)}
          </div>,
          7,
        ),
      )
      .finally(() => setShortlistingApplication(false));
  };

  const unreadNotifications = () => {
    return profile.notifications.filter(
      (notification) =>
        notification.unread &&
        notification.type === "APPLICATION_SUBMITTED" &&
        notification.action_object_id == application.id,
    );
  };

  const markAsRead = () => {
    let notifications = unreadNotifications();
    for (const notification of notifications) {
      dispatch(readNotification(notification.id));
    }
  };

  return (
    <>
      <Row style={{ justifyContent: "space-between" }}>
        <Col>
          <h3 className="content-title">{context.t("Application Details:")}</h3>
        </Col>
        {!printable && (
          <Col>
            <Button
              onClick={() =>
                history.action === "PUSH"
                  ? history.goBack()
                  : history.push(
                      `/console-employer/jobs/${jobId}/applications?status=PENDING&genders=M,F,X,N`,
                    )
              }
            >
              {context.t("Back")}
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={24}>
          <Card>
            {Object.keys(application).length == 0 ? (
              <div className="spinWrapper">
                <Spin
                  tip={context.t(
                    printable
                      ? "Preparing document, please wait.."
                      : "Loading..",
                  )}
                />
              </div>
            ) : (
              <>
                <div className="printable">
                  <h4 className={styles.label}>
                    {context.t("Basic Information")}
                  </h4>
                  <Row>
                    <Col xs={16} sm={18} md={20} lg={20}>
                      <p className={styles.paragraph}>
                        {context.t("Full Name")}&nbsp;:&nbsp;{" "}
                        {application.resume.full_name}
                      </p>
                      <p className={styles.paragraph}>
                        {context.t("Gender")}&nbsp;:&nbsp;
                        {genders[application.resume.gender] || "-"}
                      </p>
                      <p className={styles.paragraph}>
                        {context.t("Date of Birth")}&nbsp;:&nbsp;
                        {moment(application.resume.date_of_birth).format(
                          "LL",
                        ) || "-"}
                      </p>
                    </Col>
                    <Col xs={8} sm={6} md={4} lg={4}>
                      <Image
                        src={
                          application.resume.photo ||
                          "/static/media/logo-placeholder.jpg"
                        }
                        fallback={"/static/media/logo-placeholder.png"}
                        crossOrigin="anonymous"
                        style={{ objectFit: "cover" }}
                        width={80}
                        height={80}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24}>
                      <p className={styles.paragraph}>
                        {context.t("Education")}&nbsp;:&nbsp;
                        {(educationLevels.items[application.resume.education] &&
                          educationLevels.items[application.resume.education]
                            .name) ||
                          "-"}
                      </p>
                      <p className={styles.paragraph}>
                        {context.t("Nationality")}&nbsp;:&nbsp;
                        {nationalities.items[application.resume.nationality]
                          ?.name || "-"}
                      </p>
                      <p className={styles.paragraph}>
                        {context.t("Phone Number")}&nbsp;:&nbsp;
                        {application.resume.phone_number || "-"}
                      </p>
                      <p className={styles.paragraph}>
                        {context.t("Current Address")}&nbsp;:&nbsp;
                        {[
                          application.resume.address_details,
                          cities.items[application.resume.city] &&
                            cities.items[application.resume.city].name,
                          states.items[application.resume.state] &&
                            states.items[application.resume.state].name,
                          countries.items[application.resume.country] &&
                            countries.items[application.resume.country].name,
                        ]
                          .filter((x) => x)
                          .join(", ")}
                      </p>
                      <p className={styles.paragraph}>
                        {context.t("National ID")}&nbsp;:&nbsp;
                        {application.resume.national_id_number || "-"}
                      </p>
                      <p className={styles.paragraph}>
                        {context.t("Passport")}&nbsp;:&nbsp;
                        {application.resume.passport_number || "-"}
                      </p>
                      <p className={styles.paragraph}>
                        {context.t("Passport Expiry Date")}&nbsp;:&nbsp;
                        {(application.resume.passport_expire_date &&
                          moment(
                            application.resume.passport_expire_date,
                          ).format("LL")) ||
                          "-"}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24}>
                      <p className={styles.paragraph}>
                        {context.t(
                          "After going through the job description, do you think you can do the job well?",
                        )}
                        <span>
                          <i
                            className={
                              application.think_can_do_the_job_well
                                ? "fa fa-check"
                                : "fas fa-times"
                            }
                            style={{
                              color: application.think_can_do_the_job_well
                                ? "#4caf50"
                                : "#f44336",
                              margin: "0 6px",
                            }}
                          />
                          {context.t(
                            application.think_can_do_the_job_well
                              ? "Yes"
                              : "No",
                          )}
                        </span>
                      </p>
                    </Col>
                    <Col xs={24}>
                      <p className={styles.paragraph}>
                        {context.t("Do you know someone working here?")}
                        <span>
                          <i
                            className={
                              application.know_someone_working_here
                                ? "fa fa-check"
                                : "fas fa-times"
                            }
                            style={{
                              color: application.know_someone_working_here
                                ? "#4caf50"
                                : "#f44336",
                              margin: "0 6px",
                            }}
                          />
                          {context.t(
                            application.know_someone_working_here
                              ? "Yes"
                              : "No",
                          )}
                        </span>
                      </p>
                    </Col>
                  </Row>

                  <h4 className={styles.label}>
                    {context.t("Languages Proficiency")}
                  </h4>
                  <Row>
                    <Col xs={24}>
                      {Object.keys(application.resume.languages).map(
                        (key, i) => {
                          const language = languages.items[key];

                          return (
                            <div key={i}>
                              <img
                                src={language && language.flag}
                                style={{ width: 12, marginRight: 6 }}
                              />
                              <span>
                                {language && language.name}&nbsp;:&nbsp;
                                {
                                  languageProficiencyLevels[
                                    application.resume.languages[key].level
                                  ]
                                }
                              </span>
                            </div>
                          );
                        },
                      )}
                    </Col>
                  </Row>

                  <h4 className={`${styles.label} mt-1`}>
                    {context.t("Work experience")}
                  </h4>
                  <Row>
                    <Col xs={24}>
                      {application.resume.working_experience.map(
                        (experience, key) => (
                          <div key={key}>
                            <Divider
                              style={{ margin: "10px 0px" }}
                              orientation="left"
                              plain
                            >
                              {experience.title || "-"}
                            </Divider>
                            <p className={styles.paragraph}>
                              {context.t("Industry")}&nbsp;:&nbsp;
                              {(industries.items[experience.industry_id] &&
                                industries.items[experience.industry_id]
                                  .name) ||
                                "-"}
                            </p>
                            <p className={styles.paragraph}>
                              {context.t("Duration")}&nbsp;:&nbsp;
                              {context.t("{duration} years", {
                                duration: experience.years,
                              })}
                            </p>
                          </div>
                        ),
                      )}
                    </Col>
                  </Row>

                  <h4 className={`${styles.label} mt-1`}>
                    {context.t("Remark")}
                  </h4>
                  <Row>
                    <Col xs={24}>
                      <p>{application && application.remark}</p>
                    </Col>
                  </Row>

                  {!printable && application.allowed_to_view_documents && (
                    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                      <h4>{context.t("Uploaded Documents")}</h4>
                      <Row gutter={16}>
                        {profile.addresses[0].country_id == 3 ? (
                          <>
                            <Col xs={12} sm={12} md={6} lg={4}>
                              <Card
                                title={context.t("Passport")}
                                style={{ textAlign: "center" }}
                                size="small"
                              >
                                <Image
                                  src={
                                    application.profile_passport ||
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  fallback={
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  crossOrigin="anonymous"
                                  width={120}
                                  height={120}
                                />
                              </Card>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4}>
                              <Card
                                title={context.t("Identification Card")}
                                style={{ textAlign: "center" }}
                                size="small"
                              >
                                <Image
                                  src={
                                    application.profile_idcard ||
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  fallback={
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  crossOrigin="anonymous"
                                  width={120}
                                  height={120}
                                />
                              </Card>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4}>
                              <Card
                                title={context.t("Birth Certificate")}
                                style={{ textAlign: "center" }}
                                size="small"
                              >
                                <Image
                                  src={
                                    application.profile_birthcertificate ||
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  fallback={
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  crossOrigin="anonymous"
                                  width={120}
                                  height={120}
                                />
                              </Card>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4}>
                              <Card
                                title={context.t("Family Record Book")}
                                style={{ textAlign: "center" }}
                                size="small"
                              >
                                <Image
                                  src={
                                    application.profile_familyrecordbook ||
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  fallback={
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  crossOrigin="anonymous"
                                  width={120}
                                  height={120}
                                />
                              </Card>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4}>
                              <Card
                                title={context.t("Residence Book")}
                                style={{ textAlign: "center" }}
                                size="small"
                              >
                                <Image
                                  src={
                                    application.profile_residencebook ||
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  fallback={
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  crossOrigin="anonymous"
                                  width={120}
                                  height={120}
                                />
                              </Card>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4}>
                              <Card
                                title={context.t("Covid Vaccine Certificate")}
                                style={{ textAlign: "center" }}
                                size="small"
                              >
                                <Image
                                  src={
                                    application.profile_covidvaccinecertificate ||
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  fallback={
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  crossOrigin="anonymous"
                                  width={120}
                                  height={120}
                                />
                              </Card>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col xs={12} sm={12} md={6} lg={4}>
                              <Card
                                title={context.t("NRC")}
                                style={{ textAlign: "center" }}
                                size="small"
                              >
                                <Image
                                  src={
                                    application.profile_nrc ||
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  fallback={
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  crossOrigin="anonymous"
                                  width={120}
                                  height={120}
                                />
                              </Card>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4}>
                              <Card
                                title={context.t("Household List")}
                                style={{ textAlign: "center" }}
                                size="small"
                              >
                                <Image
                                  src={
                                    application.profile_householdlist ||
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  fallback={
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  crossOrigin="anonymous"
                                  width={120}
                                  height={120}
                                />
                              </Card>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4}>
                              <Card
                                title={context.t("Passport (PJ)")}
                                style={{ textAlign: "center" }}
                                size="small"
                              >
                                <Image
                                  src={
                                    application.profile_passport ||
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  fallback={
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  crossOrigin="anonymous"
                                  width={120}
                                  height={120}
                                />
                              </Card>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4}>
                              <Card
                                title={context.t("Labour Card")}
                                style={{ textAlign: "center" }}
                                size="small"
                              >
                                <Image
                                  src={
                                    application.profile_labourcard ||
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  fallback={
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  crossOrigin="anonymous"
                                  width={120}
                                  height={120}
                                />
                              </Card>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4}>
                              <Card
                                title={context.t("Covid Vaccine Certificate")}
                                style={{ textAlign: "center" }}
                                size="small"
                              >
                                <Image
                                  src={
                                    application.profile_covidvaccinecertificate ||
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  fallback={
                                    "/static/media/ImagePlaceholder.png"
                                  }
                                  crossOrigin="anonymous"
                                  width={120}
                                  height={120}
                                />
                              </Card>
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>
                  )}
                </div>

                {!printable && (
                  <Row gutter={8} className="mt-1">
                    <Col>
                      <Button
                        icon={<DownloadOutlined />}
                        onClick={downloadApplication}
                        href={`/print/console-employer/jobs/${jobId}/applications/${applicationId}`}
                        style={{ minWidth: 80 }}
                        target="_blank"
                      >
                        {context.t("Download/Print")}
                      </Button>
                    </Col>

                    {[
                      "APPLIED",
                      "DOWNLOADED",
                      "VIEWED",
                      "SHORTLISTED",
                    ].includes(application.status) && (
                      <Col>
                        <Popconfirm
                          title={context.t(
                            "Are you sure you would like to request the documents? This action cannot be undone later.",
                          )}
                          placement="top"
                          onConfirm={requestDocuments}
                          okText={context.t("Continue")}
                          cancelText={context.t("Cancel")}
                        >
                          <Button
                            type="secondary"
                            icon={<IdcardOutlined />}
                            style={{ minWidth: 100 }}
                            loading={requestingDocuments}
                          >
                            {context.t("Request documents")}
                          </Button>
                        </Popconfirm>
                      </Col>
                    )}

                    {["APPLIED", "DOWNLOADED", "VIEWED"].includes(
                      application.status,
                    ) && (
                      <Col>
                        <Popconfirm
                          title={context.t(
                            "Are you sure you would like to shortlist the application? This action cannot be undone later.",
                          )}
                          placement="top"
                          onConfirm={shortlistApplication}
                          okText={context.t("Continue")}
                          cancelText={context.t("Cancel")}
                        >
                          <Button
                            type="primary"
                            icon={<CheckOutlined />}
                            style={{ minWidth: 100 }}
                            loading={shortlistingApplication}
                          >
                            {context.t("Shortlist")}
                          </Button>
                        </Popconfirm>
                      </Col>
                    )}

                    {["SHORTLISTED"].includes(application.status) && (
                      <Col>
                        <Popconfirm
                          title={context.t(
                            "Are you sure you would like to send the job offer? This action cannot be undone later.",
                          )}
                          placement="top"
                          onConfirm={offerApplication}
                          okText={context.t("Continue")}
                          cancelText={context.t("Cancel")}
                        >
                          <Button
                            type="primary"
                            icon={<SendOutlined />}
                            style={{ minWidth: 100 }}
                            loading={offeringJob}
                          >
                            {context.t("Send offer")}
                          </Button>
                        </Popconfirm>
                      </Col>
                    )}

                    {[
                      "APPLIED",
                      "VIEWED",
                      "DOWNLOADED",
                      "SHORTLISTED",
                    ].includes(application.status) && (
                      <Col>
                        <Popconfirm
                          title={
                            <div>
                              <p>
                                {context.t(
                                  "Please select one of the reasons below.",
                                )}
                              </p>
                              <RejectionReasonSelect
                                style={{ width: "100%", minWidth: 200 }}
                                value={rejectionReason.rejection_reason}
                                onChange={(reason) =>
                                  setRejectionReason({
                                    ...rejectionReason,
                                    rejection_reason: reason,
                                    rejection_reason_other: null,
                                  })
                                }
                              />
                              <Input.TextArea
                                placeholder={context.t(
                                  "Other rejection reason",
                                )}
                                value={rejectionReason.rejection_reason_other}
                                onChange={(e) =>
                                  setRejectionReason({
                                    ...rejectionReason,
                                    rejection_reason_other: e.target.value,
                                  })
                                }
                                className="mt-1"
                                rows={3}
                                disabled={
                                  rejectionReason.rejection_reason !== 1
                                }
                              />
                            </div>
                          }
                          placement="top"
                          onConfirm={rejectApplication}
                          okText={context.t("Continue")}
                          okButtonProps={{ danger: true }}
                          cancelText={context.t("Cancel")}
                        >
                          <Button
                            type="danger"
                            icon={<StopOutlined />}
                            style={{ minWidth: 80 }}
                            loading={rejectingApplication}
                          >
                            {context.t("Reject")}
                          </Button>
                        </Popconfirm>
                      </Col>
                    )}
                  </Row>
                )}
              </>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}

EmployerApplicationsDetailedView.contextTypes = {
  t: PropTypes.func.isRequired,
};
