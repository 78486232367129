import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { Button, Image } from "antd";

const DecideRedirection = (props, context) => {
  const handleLoginWithBusinessAccount = () => {
    localStorage.removeItem("auth");
    window.location.reload();
  };

  return (
    <Row className="mt-3">
      <Col span={6} offset={9}>
        <center>
          <h3 className="mt-10">
            <Image
              width="27%"
              src="/static/media/disappointment.png"
              preview={false}
            />
            <br />
            {context.t(
              "Oops! You tried to log in with a job seeker account. Please check and try again.",
            )}
          </h3>
        </center>
        <Button
          type="primary"
          className="button-modified full-width mt-1"
          onClick={handleLoginWithBusinessAccount.bind(this)}
        >
          <strong>{context.t("Log in with Business Account")}</strong>
        </Button>
      </Col>
    </Row>
  );
};

export default DecideRedirection;

DecideRedirection.contextTypes = {
  t: PropTypes.func.isRequired,
};
